export enum TokenSymbols {
	USDT = 'USDT',
	USDC = 'USDC',
	USDt = 'USDt',
	WAVAX = 'WAVAX',
	sAVAX = 'sAVAX',
	USDV = 'USDV',
	ggAVAX = 'ggAVAX',
	'BTC.b' = 'BTC.b',
	UXDA = 'UXDA',
	QI = 'QI',
	GGP = 'GGP',
	xflow = 'xflow',
	WETH = 'WETH',
	frxETH = 'frxETH',
	sfrxETH = 'sfrxETH',
	wstETH = 'wstETH',
	ETHx = 'ETHx',
	FRAX = 'FRAX',
	EURA = 'EURA',
	EURe = 'EURe',
	mWOM = 'mWOM',
	WOM = 'WOM',
	wmxWom = 'wmxWom',
	WBTC = 'WBTC',
	FXS = 'FXS',
	SD = 'SD',
	ANGLE = 'ANGLE',
	BUSD = 'BUSD',
	DAI = 'DAI',
	lisUSD = 'lisUSD',
	qWOM = 'qWOM',
	TUSD = 'TUSD',
	BNBx = 'BNBx',
	WBNB = 'WBNB',
	stkBNB = 'stkBNB',
	iUSD = 'iUSD',
	CUSD = 'CUSD',
	axlUSDC = 'axlUSDC',
	USDD = 'USDD',
	BOB = 'BOB',
	ETH = 'ETH',
	MIM = 'MIM',
	'USD+' = 'USD+',
	'USDT+' = 'USDT+',
	ankrBNB = 'ankrBNB',
	BNBy = 'BNBy',
	wBETH = 'wBETH',
	ankrETH = 'ankrETH',
	slisBNB = 'slisBNB',
	rBNB = 'rBNB',
	USDS = 'USDS',
	zBNB = 'zBNB',
	zUSD = 'zUSD',
	lUSD = 'lUSD',
	BTCB = 'BTCB',
	WMX = 'WMX',
	MGP = 'MGP',
	QUO = 'QUO',
	TENFI = 'TENFI',
	FIS = 'FIS',
	PSTAKE = 'PSTAKE',
	SPELL = 'SPELL',
	ANKR = 'ANKR',
	SABLE = 'SABLE',
	HZN = 'HZN',
	LINA = 'LINA',
	USDbC = 'USDbC',
	'USDC.e' = 'USDC.e',
	DOLA = 'DOLA',
	OP = 'OP',
	'DAI+' = 'DAI+',
	MAI = 'MAI',
	jUSDC = 'jUSDC',
	mPendle = 'mPendle',
	PENDLE = 'PENDLE',
	fUSDC = 'fUSDC',
	sFRAX = 'sFRAX',
	ARB = 'ARB',
	PNP = 'PNP',
	JONES = 'JONES',
	vUSDC = 'vUSDC',
	HAY = 'HAY',
	wmxWOM = 'wmxWOM',
	BTC = 'BTC',
	RT1 = 'RT1',
	RT2 = 'RT2'
}
