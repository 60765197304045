import {
  HardcodedTokenSymbol,
  TokenSymbol,
  TokenSymbols,
} from '../../constants/contract/token/TokenSymbols'
import ANKR from '../../public/assets/tokens/ANKR.svg'
import ARB from '../../public/assets/tokens/ARB.svg'
import WAVAX from '../../public/assets/tokens/AVAX.svg'
import { default as BNB } from '../../public/assets/tokens/BNB.svg'
import BNBx from '../../public/assets/tokens/BNBx.svg'
import BNBy from '../../public/assets/tokens/BNBy.svg'
import BOB from '../../public/assets/tokens/BOB.svg'
import BTC from '../../public/assets/tokens/BTC.svg'
import { default as BUSD } from '../../public/assets/tokens/BUSD.svg'
import CUSD from '../../public/assets/tokens/CUSD.svg'
import DAI from '../../public/assets/tokens/DAI.svg'
import DAIplus from '../../public/assets/tokens/DAIplus.svg'
import DOLA from '../../public/assets/tokens/DOLA.svg'
import ETH from '../../public/assets/tokens/ETH.svg'
import FIS from '../../public/assets/tokens/FIS.svg'
import FRAX from '../../public/assets/tokens/FRAX.svg'
import FRXETH from '../../public/assets/tokens/FRXETH.svg'
import FXS from '../../public/assets/tokens/FXS.svg'
import GGP from '../../public/assets/tokens/GGP.svg'
import hzn from '../../public/assets/tokens/HZN.svg'
import JONES from '../../public/assets/tokens/JONES.svg'
import MAI from '../../public/assets/tokens/MAI.svg'
import MGP from '../../public/assets/tokens/MGP.svg'
import MIM from '../../public/assets/tokens/MIM.svg'
import OP from '../../public/assets/tokens/OP.svg'
import PENDLE from '../../public/assets/tokens/PENDLE.svg'
import PNP from '../../public/assets/tokens/PNP.svg'
import PSTAKE from '../../public/assets/tokens/PSTAKE.svg'
import QI from '../../public/assets/tokens/QI.svg'
import QUOLL from '../../public/assets/tokens/QUOLL.svg'
import QI_BENQI from '../../public/assets/tokens/Qi_BENQI.svg'
import SABLE from '../../public/assets/tokens/SABLE.svg'
import SPELL from '../../public/assets/tokens/SPELL.svg'
import STADER from '../../public/assets/tokens/STADER.svg'
import TEN from '../../public/assets/tokens/TEN.svg'
import TUSD from '../../public/assets/tokens/TUSD.svg'
import USDC from '../../public/assets/tokens/USDC.svg'
import USDD from '../../public/assets/tokens/USDD.svg'
import USDS from '../../public/assets/tokens/USDS.svg'
import USDT from '../../public/assets/tokens/USDT.svg'
import USDTplus from '../../public/assets/tokens/USDTplus.svg'
import USDV from '../../public/assets/tokens/USDV.svg'
import USDplus from '../../public/assets/tokens/USDplus.svg'
import UXDA from '../../public/assets/tokens/UXDA.svg'
import WETH from '../../public/assets/tokens/WETH.svg'
import WMX from '../../public/assets/tokens/WMX.svg'
import WOM from '../../public/assets/tokens/WOM.svg'
import aBNBc from '../../public/assets/tokens/aBNBc.svg'
import ankrETH from '../../public/assets/tokens/ankrETH.svg'
import axlUSDC from '../../public/assets/tokens/axlUSDC.svg'
import ANGLE from '../../public/assets/tokens/ethereum/ETH-ANGLE.svg'
import ETHX from '../../public/assets/tokens/ethereum/ETH-ETHx.svg'
import EURA from '../../public/assets/tokens/ethereum/ETH-EURA.svg'
import EURe from '../../public/assets/tokens/ethereum/ETH-EURe.svg'
import LBR from '../../public/assets/tokens/ethereum/ETH-LBR.svg'
import ETH_ANKR from '../../public/assets/tokens/ethereum/ETH-ankr.svg'
import eUSD from '../../public/assets/tokens/ethereum/ETH-eUSD.svg'
import rETH from '../../public/assets/tokens/ethereum/ETH-rETH.svg'
import fUSDC from '../../public/assets/tokens/fUSDC.svg'
import ggAVAX from '../../public/assets/tokens/ggAVAX.svg'
import iUSD from '../../public/assets/tokens/iUSD.svg'
import jUSDC from '../../public/assets/tokens/jUSDC.svg'
import lUSD from '../../public/assets/tokens/lUSD.svg'
import LINA from '../../public/assets/tokens/lina.svg'
import lisUSD from '../../public/assets/tokens/lisUSD.svg'
import mPendle from '../../public/assets/tokens/mPendle.svg'
import mWOM from '../../public/assets/tokens/mWOM.svg'
import qWOM from '../../public/assets/tokens/qWOM.svg'
import rBNB from '../../public/assets/tokens/rBNB.svg'
import sAVAX from '../../public/assets/tokens/sAVAX.svg'
import sFRAX from '../../public/assets/tokens/sFRAX.svg'
import sfrxETH from '../../public/assets/tokens/sfrxETH.svg'
import slisBNB from '../../public/assets/tokens/slisBNB.svg'
import stkBNB from '../../public/assets/tokens/stkBNB.svg'
import vUSDC from '../../public/assets/tokens/vUSDC.svg'
import wBETH from '../../public/assets/tokens/wBETH.svg'
import wmxWOM from '../../public/assets/tokens/wmxWOM.svg'
import wstETH from '../../public/assets/tokens/wstETH.svg'
import zBnb from '../../public/assets/tokens/zBNB.svg'
import zUsd from '../../public/assets/tokens/zUSD.svg'
import { SupportedChainId } from '../web3/supportedChainId'

/** New Pool Release: Adding token images */
export const baseImageMap: { [token in TokenSymbol]?: string } = {
  [TokenSymbols.MAI]: MAI,
  [TokenSymbols.QI]: QI,
  [TokenSymbols['DAI+']]: DAIplus,
  [TokenSymbols.SPELL]: SPELL,
  [TokenSymbols['USDT+']]: USDTplus,
  [TokenSymbols['USD+']]: USDplus,
  [TokenSymbols.USDD]: USDD,
  [TokenSymbols.axlUSDC]: axlUSDC,
  [TokenSymbols.CUSD]: CUSD,
  [TokenSymbols.iUSD]: iUSD,
  [TokenSymbols.BUSD]: BUSD,
  [TokenSymbols.DAI]: DAI,
  [TokenSymbols.TUSD]: TUSD,
  [TokenSymbols.USDT]: USDT,
  // USDT's symbol is USDt on avalanche */
  [TokenSymbols.USDt]: USDT,
  [TokenSymbols.USDC]: USDC,
  [TokenSymbols['USDC.e']]: USDC,
  [TokenSymbols.vUSDC]: vUSDC,
  [TokenSymbols.FRAX]: FRAX,
  [TokenSymbols.MIM]: MIM,
  [TokenSymbols.lisUSD]: lisUSD,
  [TokenSymbols.WBNB]: BNB,
  [TokenSymbols.BNBx]: BNBx,
  [TokenSymbols.stkBNB]: stkBNB,
  [TokenSymbols.ankrBNB]: aBNBc,
  [TokenSymbols.RT1]: BUSD,
  // use different icons for better visualisation
  [TokenSymbols.RT2]: WOM,
  [TokenSymbols.WOM]: WOM,
  [TokenSymbols.qWOM]: qWOM,
  [TokenSymbols.mWOM]: mWOM,
  [TokenSymbols.wmxWOM]: wmxWOM,
  /** @todo the token name on mainnet is called wmxWom but not wmxWOM */
  [TokenSymbols.wmxWom]: wmxWOM,
  [TokenSymbols.WMX]: WMX,
  [TokenSymbols.QUO]: QUOLL,
  [TokenSymbols.MGP]: MGP,
  [TokenSymbols.ANKR]: ANKR,
  [HardcodedTokenSymbol.newANKR]: ANKR,
  [TokenSymbols.PSTAKE]: PSTAKE,
  [TokenSymbols.SD]: STADER,
  [TokenSymbols.BOB]: BOB,
  [TokenSymbols.WETH]: WETH,
  [TokenSymbols.ETH]: ETH,
  [TokenSymbols.frxETH]: FRXETH,
  [TokenSymbols.sfrxETH]: sfrxETH,
  [TokenSymbols.FXS]: FXS,
  [TokenSymbols.BNBy]: BNBy,
  [TokenSymbols.TENFI]: TEN,
  [TokenSymbols.jUSDC]: jUSDC,
  [TokenSymbols.JONES]: JONES,
  [TokenSymbols.wBETH]: wBETH,
  [TokenSymbols.ankrETH]: ankrETH,
  [TokenSymbols.wstETH]: wstETH,
  [TokenSymbols.ARB]: ARB,
  [TokenSymbols.fUSDC]: fUSDC,
  [TokenSymbols.mPendle]: mPendle,
  [TokenSymbols.PENDLE]: PENDLE,
  [TokenSymbols.PNP]: PNP,
  [TokenSymbols.slisBNB]: slisBNB,
  [TokenSymbols.rBNB]: rBNB,
  [TokenSymbols.FIS]: FIS,
  [TokenSymbols.USDS]: USDS,
  [HardcodedTokenSymbol.rETH]: rETH,
  [HardcodedTokenSymbol.LBR]: LBR,
  [TokenSymbols.ETHx]: ETHX,
  [HardcodedTokenSymbol.eUSD]: eUSD,
  [TokenSymbols.EURA]: EURA,
  [TokenSymbols.EURe]: EURe,
  [TokenSymbols.ANGLE]: ANGLE,
  [TokenSymbols.SABLE]: SABLE,
  [TokenSymbols.zBNB]: zBnb,
  [TokenSymbols.zUSD]: zUsd,
  [TokenSymbols.HZN]: hzn,
  [TokenSymbols.USDbC]: USDC,
  [TokenSymbols.WAVAX]: WAVAX,
  [TokenSymbols.sAVAX]: sAVAX,
  [TokenSymbols.USDV]: USDV,
  [TokenSymbols.DOLA]: DOLA,
  [TokenSymbols.OP]: OP,
  [TokenSymbols.sFRAX]: sFRAX,
  [TokenSymbols.ggAVAX]: ggAVAX,
  [TokenSymbols.GGP]: GGP,
  [TokenSymbols.LINA]: LINA,
  [TokenSymbols.lUSD]: lUSD,
  [TokenSymbols.BTCB]: BTC,
  [TokenSymbols.WBTC]: BTC,
  [TokenSymbols['BTC.b']]: BTC,
  [TokenSymbols.UXDA]: UXDA,
}

export const getImageMap = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.ETHEREUM_MAINNET:
      return {
        ...baseImageMap,
        [TokenSymbols.WETH]: ETH,
        [TokenSymbols.ANKR]: ETH_ANKR,
      }
    case SupportedChainId.ARBITRUM_ONE:
      return {
        ...baseImageMap,
        [TokenSymbols.WETH]: ETH,
      }
    case SupportedChainId.BSC_MAINNET:
      return {
        ...baseImageMap,
        [TokenSymbols.WETH]: ETH,
      }
    case SupportedChainId.AVAX:
      return {
        ...baseImageMap,
        [TokenSymbols.QI]: QI_BENQI,
      }
    case SupportedChainId.SCROLL_MAINNET:
      return {
        ...baseImageMap,
        [TokenSymbols.WETH]: ETH,
      }
    default:
      return baseImageMap
  }
}
