import { BigNumber, ContractTransaction, utils } from 'ethers'
import Image from 'next/image'
import { useEffect, useMemo, useState } from 'react'
import { LATEST_MASTER_WOMBATS, TOKENS } from '../../constants/contract'
import { Asset } from '../../constants/contract/asset/Asset'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { useWeb3 } from '../../context/Web3Context'
import { useMasterWombat } from '../../context/masterWombatContext'
import useApproval, { TokenApprovalState } from '../../hooks/useApproval'
import useHandleStake, { HandleStakeState } from '../../hooks/useHandleStake'
import WOMicon from '../../public/assets/tokens/WOM.svg'
import { useTokenData, useVewomData } from '../../store/MulticallData/hooks'
import commafy, { commafyPercentage } from '../../utils/commafy'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import WaitingModal from '../Modal/WaitingModal'
import PoolInput from '../PoolInput'
import { POOL_STATE, TokenInfo } from '../PoolPage'
import TokenImage from '../TokenImage'
import TransactionFailedModal from '../TransactionFailedModal'
import SubmittedWithdraw from '../TransactionSubmittedModal'
import WButton, { Variant } from '../WButton'

interface Props {
  currentPoolLabel: PoolLabels
  asset: Asset
  tokenInfo: TokenInfo
  depositedAmount: string | undefined
  stakedAmount: string | undefined
  onTxnSubmited: (txn: ContractTransaction) => void
}

export default function Stake({
  currentPoolLabel,
  asset,
  tokenInfo,
  depositedAmount,
  stakedAmount,
  onTxnSubmited,
}: Props) {
  const stakedAmountUi = Number(stakedAmount).toFixed(2)
  const [tokenAmount, setTokenAmount] = useState<BigNumber | null>(null)
  const [estimatedBoostedAPR, setEstimatedBoostedAPR] = useState<number | null>(null)
  const [isDisplayTxnModal, setIsDisplayTxnModal] = useState<boolean>(false)
  const { chainId, account } = useWeb3()
  const { withAccount } = useTokenData()
  const balances = withAccount?.balances
  const { approvalState, tryApproval } = useApproval(
    asset.address,
    LATEST_MASTER_WOMBATS[chainId].address,
    tokenAmount
  )
  const token = TOKENS[chainId][asset.symbol]
  const { tryHandleStake, stakeState, txnHash, isDisplayGifFlyToTheMoon } = useHandleStake(
    currentPoolLabel,
    asset,
    tokenAmount,
    onTxnSubmited,
    formatNumberUSLocale(utils.formatEther(tokenAmount || 0)) + ' LP-' + asset.displaySymbol
  )

  const {
    userInfos,
    actions: { estimateBoostedApr },
  } = useMasterWombat()
  const vewomData = useVewomData()
  const vewomBalanceWad = useMemo(() => {
    return vewomData.withAccount?.vewomBalanceWad || null
  }, [vewomData.withAccount?.vewomBalanceWad])
  const { baseAPR, bonusTokens } = tokenInfo
  const userStakedAmount = userInfos[currentPoolLabel][asset.symbol]?.amount ?? BigNumber.from(0)
  const baseAPR_UI = baseAPR ? commafyPercentage(baseAPR) : '-'
  const boostAPR_UI = estimatedBoostedAPR ? commafyPercentage(estimatedBoostedAPR) : '-'
  const extraAprs =
    bonusTokens
      ?.map((rewardInfo) => Number(rewardInfo.apr) / 1e18)
      .reduce((partialSum, a) => partialSum + a, 0) ?? 0

  const myTotalApr: number = (baseAPR ?? 0) + (estimatedBoostedAPR ?? 0) + extraAprs
  const myTotalAprUi =
    Number(userStakedAmount) > 0 || Number(tokenAmount) > 0 ? commafyPercentage(myTotalApr) : '-'

  useEffect(() => {
    const apr = estimateBoostedApr(
      currentPoolLabel,
      asset.symbol,
      userStakedAmount,
      vewomBalanceWad ?? BigNumber.from(0),
      (tokenAmount ?? BigNumber.from(0)).add(userStakedAmount),
      vewomBalanceWad ?? BigNumber.from(0)
    )
    setEstimatedBoostedAPR(Number(utils.formatEther(apr || 0)))
  }, [
    asset.symbol,
    currentPoolLabel,
    estimateBoostedApr,
    tokenAmount,
    userStakedAmount,
    vewomBalanceWad,
  ])

  const transactionModal = () => {
    switch (stakeState) {
      case HandleStakeState.FAILED:
        return <TransactionFailedModal isOpen onClose={() => setIsDisplayTxnModal(false)} />
      case HandleStakeState.SUBMITED:
        return (
          <SubmittedWithdraw
            isBoostAPR
            isAddTokenToWallet={false}
            token={token}
            hash={txnHash}
            isOpen
            onClose={() => setIsDisplayTxnModal(false)}
            chainId={chainId}
          />
        )
      case HandleStakeState.LOADING:
        return (
          <WaitingModal
            textAboveBalance="Stake"
            isDisplayGifFlyToTheMoon={isDisplayGifFlyToTheMoon}
            data={
              formatNumberUSLocale(utils.formatEther(tokenAmount ?? 0)) +
              ' LP-' +
              asset.displaySymbol
            }
            isOpen
            onClose={() => setIsDisplayTxnModal(false)}
          />
        )
    }
  }

  useEffect(() => {
    if (stakeState === HandleStakeState.SUBMITED) {
      setTokenAmount(BigNumber.from(0))
    }
  }, [stakeState])

  function isAmountGreaterMappingAmount() {
    return depositedAmount && tokenAmount ? tokenAmount.gt(utils.parseEther(depositedAmount)) : true
  }

  const button = () => {
    switch (true) {
      case approvalState === TokenApprovalState.UNKNOWN:
      case approvalState === TokenApprovalState.NETWORK_UNSUPPORTED:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
              APPROVE
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                STAKE
              </WButton>
            </div>
          </div>
        )

      case approvalState === TokenApprovalState.NOT_APPROVED:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton
              variant={Variant.GRADIENT}
              width="w-full"
              className={`stake-${asset.symbol}-approve`}
              onClick={() => tryApproval(tokenAmountForDisplay, token?.decimals)}
            >
              APPROVE
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                STAKE
              </WButton>
            </div>
          </div>
        )

      case approvalState === TokenApprovalState.LOADING:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton variant={Variant.GRADIENT} width="w-full" isLoading={true}></WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                STAKE
              </WButton>
            </div>
          </div>
        )

      case stakeState === HandleStakeState.LOADING:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full">
              APPROVED
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.GRADIENT} width="w-full" isLoading={true} />
            </div>
          </div>
        )

      case approvalState === TokenApprovalState.APPROVED:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
              APPROVED
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              {!tokenAmount || tokenAmount?.eq(0) || isAmountGreaterMappingAmount() ? (
                <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                  STAKE
                </WButton>
              ) : (
                <WButton
                  variant={Variant.GRADIENT}
                  width="w-full"
                  onClick={() => {
                    setIsDisplayTxnModal(true)
                    tryHandleStake(() => {
                      setTokenAmountForDisplay('')
                    })
                  }}
                  className={`stake-${asset.symbol}-stake`}
                >
                  STAKE
                </WButton>
              )}
            </div>
          </div>
        )
      default:
        break
    }
  }

  const [tokenAmountForDisplay, setTokenAmountForDisplay] = useState<string>('')

  const showAPR = true

  return (
    <>
      {token && (
        <PoolInput
          balance={balances?.[asset.symbol]}
          poolState={POOL_STATE.STAKE}
          token={token}
          selectedAsset={asset}
          setTokenAmount={setTokenAmount}
          tokenAmountForDisplay={tokenAmountForDisplay}
          setTokenAmountForDisplay={setTokenAmountForDisplay}
          handleChangeAmount={(value) => {
            setTokenAmount(value)
          }}
          amountTokenMapping={depositedAmount ? depositedAmount : '0'}
          decimals={18}
        />
      )}

      <div className="py-4 font-Work text-sm text-wombatBrown">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="mr-2">Staked LP</span>
          </div>
          <span>
            {stakedAmountUi
              ? `${formatNumberUSLocale(stakedAmountUi)} LP-${asset.displaySymbol}`
              : '-'}
          </span>
        </div>
        {showAPR && (
          <div>
            <br />
            <div className="pb-1.5">
              <p className="opacity-80">Base APR</p>
              <div className="flex justify-between">
                <div className="flex">
                  <Image alt={''} src={WOMicon} height={16} width={16} />
                  <div className="ml-1">WOM</div>
                </div>
                <span>{baseAPR_UI}</span>
              </div>
              {bonusTokens?.map((rewardInfo) => {
                if (rewardInfo.amount.eq('0')) {
                  return
                }

                return (
                  <div className="flex justify-between" key={rewardInfo.symbol}>
                    <div className="flex">
                      <TokenImage tokenSymbol={rewardInfo.symbol} width="16px" height="16px" />
                      <div className="ml-1">{rewardInfo.symbol}</div>
                    </div>
                    <span>{`${commafy(
                      ((Number(rewardInfo.apr) / 1e18) * 100).toFixed(2).toString()
                    )}%`}</span>
                  </div>
                )
              })}
            </div>
            <p className="opacity-80">Boosted APR</p>
            <div className="flex justify-between pb-1.5">
              <div className="flex">
                <Image alt={''} src={WOMicon} height={16} width={16} />
                <div className="ml-1">WOM</div>
              </div>
              <span>{boostAPR_UI}</span>
            </div>
            <hr className="border border-wombatPurple3" />
            <div className="flex items-center justify-between pt-2">
              <div className="flex items-center">
                <span className="mr-2">My Total APR</span>
              </div>
              <span className="font-bold">{myTotalAprUi}</span>
            </div>
          </div>
        )}
      </div>

      {button()}
      {tokenAmount && account && isAmountGreaterMappingAmount() && (
        <div className="-mb-2 mt-2 text-center text-red-600">Insufficient balance.</div>
      )}
      {isDisplayTxnModal && transactionModal()}
    </>
  )
}
