import { AssetConfig } from '.'
import { PoolLabels } from '../../pool/PoolLabels'

export const defaultAssetConfig: AssetConfig = {
  [PoolLabels.MAIN]: {},
  [PoolLabels.SIDE]: {},
  [PoolLabels.BNB]: {},
  [PoolLabels.qWOM]: {},
  [PoolLabels.mWOM]: {},
  [PoolLabels.wmxWOM]: {},
  [PoolLabels.FACTORY_STABLES]: {},
  [PoolLabels.BNBx]: {},
  [PoolLabels.stkBNB]: {},
  [PoolLabels.axlUSDC]: {},
  [PoolLabels.CUSD]: {},
  [PoolLabels.iUSD]: {},
  [PoolLabels.USDD]: {},
  [PoolLabels.BOB]: {},
  [PoolLabels.frxETH]: {},
  [PoolLabels.MIM]: {},
  [PoolLabels.Overnight]: {},
  [PoolLabels.FRAX_MAI_USDplus]: {},
  [PoolLabels.ankrBNB]: {},
  [PoolLabels.BNBy]: {},
  [PoolLabels.jUSDC]: {},
  [PoolLabels.SMART_HAY]: {},
  [PoolLabels.wBETH]: {},
  [PoolLabels.ankrETH]: {},
  [PoolLabels.wstETH]: {},
  [PoolLabels.fUSDC]: {},
  [PoolLabels.mPendle]: {},
  [PoolLabels.slisBNB]: {},
  [PoolLabels.CROSS_CHAIN]: {},
  [PoolLabels.rBNB]: {},
  [PoolLabels.FRAX]: {},
  [PoolLabels.FRAX_USDV]: {},
  [PoolLabels.EURA]: {},
  [PoolLabels.ETHx]: {},
  [PoolLabels.USDS]: {},
  [PoolLabels.zUSD]: {},
  [PoolLabels.zBNB]: {},
  [PoolLabels.sAVAX]: {},
  [PoolLabels.USDV]: {},
  [PoolLabels.DOLA]: {},
  [PoolLabels.FRAX_sFRAX_USDC]: {},
  [PoolLabels.ggAVAX_AVAX]: {},
  [PoolLabels.lUSD]: {},
  [PoolLabels.STABLE]: {},
  [PoolLabels.AXON_STABLE]: {},
}
